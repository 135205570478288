import revive_payload_client_RAk3qhI6DU from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0LGSrgMJcb from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aYniKbgjlx from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZQ9xsBcfS0 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7rjv64pUjh from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Sbd8a7U3AU from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_njkAqDDptN from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UoIjCsyJXL from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_NNB8fmAprb from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_hqggunxgq4wpvtps4kim3pfdce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_5DgZGTFHR2 from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.1.0_@babel+parser@7.25.8_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_webpack-sources@3.2.3/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_RAk3qhI6DU,
  unhead_0LGSrgMJcb,
  router_aYniKbgjlx,
  payload_client_ZQ9xsBcfS0,
  navigation_repaint_client_7rjv64pUjh,
  check_outdated_build_client_Sbd8a7U3AU,
  chunk_reload_client_njkAqDDptN,
  plugin_vue3_UoIjCsyJXL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NNB8fmAprb,
  primevue_plugin_egKpok8Auk,
  plugin_client_5DgZGTFHR2,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]